@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.imageWrap {
  backface-visibility: hidden;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: all .3s;
  position: relative;
  transform: translateZ(0)scale(1.1);
}

@media (width >= 769px) {
  .imageWrap {
    position: relative;
  }

  .imageWrap:before {
    backface-visibility: hidden;
    content: "";
    opacity: 0;
    z-index: 10;
    border: 1px solid #fff;
    transition: all .3s;
    position: absolute;
    inset: 18px;
  }

  .imageWrap:hover:before {
    opacity: 1;
  }

  .imageWrap:hover {
    transform: translateZ(0)scale(1.001);
  }
}

.imageWrap:after {
  content: "";
  padding-bottom: 85%;
  display: block;
}

.tiles {
  flex-wrap: wrap;
  padding: 18px 0;
  display: flex;
}

.tileSubTitle {
  color: #888;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
}

.tileLink {
  display: block;
  overflow: hidden;
}

.tileSpacing {
  border: 1px solid #ddd;
  margin: 9px;
}

.tileBig {
  flex-basis: 50%;
}

.tileBig .tileLink {
  flex-basis: 66%;
  position: relative;
}

.tileBig .hasModules {
  display: flex;
}

.tileBig .modules {
  flex-direction: column;
  flex-basis: 34%;
  padding: 18px;
  display: flex;
}

.tileBig .title {
  z-index: 10;
  letter-spacing: -.5px;
  color: #fff;
  text-align: center;
  pointer-events: none;
  padding: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tileSmall {
  flex-basis: 25%;
  position: relative;
}

.tileSmall .title {
  z-index: 10;
  color: #fff;
  text-align: center;
  pointer-events: none;
  padding: 0 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
}

@media (width >= 769px) {
  .tileSmall .imageWrap {
    position: relative;
  }

  .tileSmall .imageWrap:before {
    backface-visibility: hidden;
    content: "";
    opacity: 0;
    z-index: 10;
    border: 1px solid #fff;
    transition: all .3s;
    position: absolute;
    inset: 9px;
  }

  .tileSmall .imageWrap:hover:before {
    opacity: 1;
  }
}

.tileSmall .imageWrap:after {
  padding-bottom: 35%;
}

.categoryProducts {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  display: flex;
}

.categoryProduct {
  text-align: center;
  flex-basis: 50%;
  padding-top: 18px;
}

.categoryProductImageWrap {
  border: 1px solid #c2a661;
  border-radius: 999px;
  width: 50%;
  margin: auto;
  padding-bottom: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.categoryProductImage {
  backface-visibility: hidden;
  max-width: 65%;
  height: auto;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.categoryProductTitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 1.2;
  text-decoration: none;
  display: inline-block;
}

@media (width <= 1024px) {
  .tileBig {
    flex-basis: 100%;
  }

  .tileSmall {
    flex-basis: 50%;
  }
}

@media (width <= 768px) {
  .tiles {
    padding-bottom: 0;
  }

  .tileBig .tileSpacing {
    flex-direction: column;
  }

  .tileBig .categoryProducts {
    flex-direction: row;
  }

  .tileBig .tileSpacing {
    margin: 0 9px 18px;
  }

  .tileSmall {
    flex-basis: 100%;
  }

  .tileSmall + .tileSmall {
    margin-top: -9px;
    margin-bottom: 9px;
  }

  .tileSmall .tileSpacing {
    margin: 0 -9px;
  }

  .categoryProduct {
    padding: 18px 9px 0;
  }

  .categoryProductImageWrap {
    width: 70%;
    padding-bottom: 70%;
  }
}
