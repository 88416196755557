.header {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  padding: 45px 27px 27px;
  text-align: center;
}

.h1 {
  font-style: none;
  font-weight: normal;
  font-size: 32px;
  line-height: 1.4;
}

.headerParticle {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0;
  padding-right: 9px;
}

.h2 {
  font-style: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  margin: 6px 0 0;
}