@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.imageWrap {
  backface-visibility: hidden;
  transition: all 0.3s ease;
  transform: translateZ(0) scale(1.1);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  padding: 0;
}
@media (min-width: 769px) {
  .imageWrap {
    position: relative;
  }
  .imageWrap:before {
    position: absolute;
    top: 18px;
    left: 18px;
    right: 18px;
    bottom: 18px;
    backface-visibility: hidden;
    transition: all 0.3s ease;
    content: "";
    border: 1px solid #fff;
    opacity: 0;
    z-index: 10;
  }
  .imageWrap:hover:before {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .imageWrap:hover {
    transform: translateZ(0) scale(1.001);
  }
}
.imageWrap:after {
  content: "";
  display: block;
  padding-bottom: 85%;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  padding: 18px 0;
}

.tileSubTitle {
  font-size: 14px;
  font-weight: 300;
  color: #888;
  text-align: center;
  line-height: 1.25;
}

.tileLink {
  overflow: hidden;
  display: block;
}

.tileSpacing {
  margin: 9px;
  border: 1px solid #ddd;
}

.tileBig {
  flex-basis: 50%;
}
.tileBig .tileLink {
  position: relative;
  flex-basis: 66%;
}
.tileBig .hasModules {
  display: flex;
}
.tileBig .modules {
  flex-basis: 34%;
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.tileBig .title {
  z-index: 10;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 18px;
  line-height: 2;
  color: #fff;
  text-align: center;
  pointer-events: none;
}

.tileSmall {
  position: relative;
  flex-basis: 25%;
}
.tileSmall .title {
  font-size: 14px;
  font-weight: 300;
  z-index: 10;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  padding: 0 18px;
  line-height: 1;
  color: #fff;
  text-align: center;
  pointer-events: none;
}
@media (min-width: 769px) {
  .tileSmall .imageWrap {
    position: relative;
  }
  .tileSmall .imageWrap:before {
    position: absolute;
    top: 9px;
    left: 9px;
    right: 9px;
    bottom: 9px;
    backface-visibility: hidden;
    transition: all 0.3s ease;
    content: "";
    border: 1px solid #fff;
    opacity: 0;
    z-index: 10;
  }
  .tileSmall .imageWrap:hover:before {
    opacity: 1;
  }
}
.tileSmall .imageWrap:after {
  padding-bottom: 35%;
}

.categoryProducts {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
}

.categoryProduct {
  flex-basis: 50%;
  padding-top: 18px;
  text-align: center;
}

.categoryProductImageWrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 50%;
  padding-bottom: 50%;
  margin: auto;
  border-radius: 999px;
  border: 1px solid #c2a661;
}

.categoryProductImage {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 65%;
  max-height: 65%;
  height: auto;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}

.categoryProductTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: inline-block;
  padding-top: 9px;
  line-height: 1.2;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .tileBig {
    flex-basis: 100%;
  }
  .tileSmall {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .tiles {
    padding-bottom: 0;
  }
  .tileBig .tileSpacing {
    flex-direction: column;
  }
  .tileBig .categoryProducts {
    flex-direction: row;
  }
  .tileBig .tileSpacing {
    margin: 0 9px 18px;
  }
  .tileSmall {
    flex-basis: 100%;
  }
  .tileSmall + .tileSmall {
    margin-bottom: 9px;
    margin-top: -9px;
  }
  .tileSmall .tileSpacing {
    margin: 0 -9px;
  }
  .categoryProduct {
    padding: 18px 9px 0;
  }
  .categoryProductImageWrap {
    width: 70%;
    padding-bottom: 70%;
  }
}