@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.roomList {
  flex-wrap: wrap;
  padding-bottom: 36px;
  display: flex;
}

.room {
  flex-basis: 25%;
  padding: 9px;
  position: relative;
}

.roomHeader {
  letter-spacing: -.5px;
  text-align: center;
  padding: 18px 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.roomLink {
  display: block;
  overflow: hidden;
}

.roomTitle {
  z-index: 10;
  letter-spacing: -.5px;
  text-align: center;
  color: #222;
  pointer-events: none;
  padding: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  position: absolute;
  left: 0;
  right: 0;
}

.roomImage {
  backface-visibility: hidden;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: all .3s;
  position: relative;
  transform: translateZ(0)scale(1.1);
}

@media (width >= 769px) {
  .roomImage {
    position: relative;
  }

  .roomImage:before {
    backface-visibility: hidden;
    content: "";
    opacity: 0;
    z-index: 10;
    border: 1px solid #fff;
    transition: all .3s;
    position: absolute;
    inset: 18px;
  }

  .roomImage:hover:before {
    opacity: 1;
  }

  .roomImage:hover {
    transform: translateZ(0)scale(1.001);
  }
}

.roomImage:after {
  content: "";
  padding-bottom: 85%;
  display: block;
}

@media (width <= 1024px) {
  .roomList {
    padding-bottom: 18px;
  }

  .room {
    flex-basis: 50%;
  }
}

@media (width <= 768px) {
  .roomTitle {
    padding: 9px;
    font-size: 16px;
    font-weight: 300;
  }
}
