.header {
  text-align: center;
  padding: 45px 27px 27px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
}

.h1 {
  font-style: none;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.4;
}

.headerParticle {
  letter-spacing: 0;
  padding-right: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: italic;
  font-weight: normal;
}

.h2 {
  font-style: none;
  margin: 6px 0 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
}
