@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
.roomList {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 36px;
}

.room {
  position: relative;
  flex-basis: 25%;
  padding: 9px;
}

.roomHeader {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 18px 0;
}

.roomLink {
  overflow: hidden;
  display: block;
}

.roomTitle {
  z-index: 10;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  position: absolute;
  left: 0;
  right: 0;
  padding: 18px;
  text-align: center;
  color: #222;
  pointer-events: none;
}

.roomImage {
  backface-visibility: hidden;
  transition: all 0.3s ease;
  transform: translateZ(0) scale(1.1);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  padding: 0;
}
@media (min-width: 769px) {
  .roomImage {
    position: relative;
  }
  .roomImage:before {
    position: absolute;
    top: 18px;
    left: 18px;
    right: 18px;
    bottom: 18px;
    backface-visibility: hidden;
    transition: all 0.3s ease;
    content: "";
    border: 1px solid #fff;
    opacity: 0;
    z-index: 10;
  }
  .roomImage:hover:before {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .roomImage:hover {
    transform: translateZ(0) scale(1.001);
  }
}
.roomImage:after {
  content: "";
  display: block;
  padding-bottom: 85%;
}

@media (max-width: 1024px) {
  .roomList {
    padding-bottom: 18px;
  }
  .room {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .roomTitle {
    font-size: 16px;
    font-weight: 300;
    padding: 9px;
  }
}